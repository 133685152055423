<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img src="../../assets/images/logo/logo-green.png" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5" style="background-color:#f0f9f5 ;">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid style="width: 70%;" :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="row auth-bg px-2 p-2 pt-3" style="border-left: solid 1px #182c25">
        <div class="col-md-12 pr-0 languages-mobile" align="end">
          <b-dropdown
            text="Dark"
            variant="outline-dark"
            id="dropdown-grouped"
            class="dropdown-language no-border-button"
            right
          >
            <template #button-content>
              <b-img
                :src="currentLocale.img"
                height="14px"
                width="22px"
                :alt="currentLocale.locale"
              />
              <span class="ml-50 text-body">{{ $t(currentLocale.name) }}</span>
            </template>
            <b-dropdown-item
              v-for="localeObj in locales"
              :key="localeObj.locale"
              @click="$i18n.locale = localeObj.locale"
            >
              <b-img
                :src="localeObj.img"
                height="14px"
                width="22px"
                :alt="localeObj.locale"
              />
              <span class="ml-50">{{ $t(localeObj.name) }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <b-col sm="12" md="12" lg="12" class="px-xl-2 mx-auto mt-2">
          <div class="col-md-12">
            <h4 class="row justify-content-between">
              <b-card-title
                title-tag="h2"
                class="font-weight-bold mb-2 col-md-12 pl-0"
              >
                {{ $t("welcome_to_hive") }}
              </b-card-title>
            </h4>
          </div>
          <b-card-text class="mb-2">
            {{ $t("login_subtitle") }}
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import axios from "axios";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      amount: "",
      sideImg: require("@/assets/images/pages/login-v5.png"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      disableInputs: false,
      language: "en",
      locales: [
        {
          locale: "en",
          img: "/images/flags/en.png",
          name: "english",
        },

        {
          locale: "de",
          img: "/images/flags/german.png",
          name: "german",
        },
      ],
    };
  },
  created(){
    this.loginFromAdmin();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v5.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
    currentLocale() {
      let obj = null;
      if (this.$i18n.locale) {
        if (this.$i18n.locale == "en") {
          obj = {
            locale: "en",
            img: "/images/flags/en.png",
            name: "english",
          };
        } else {
          obj = {
            locale: "de",
            img: "/images/flags/german.png",
            name: "german",
          };
        }
      } else {
        obj = {
          locale: "en",
          img: "/images/flags/en.png",
          name: "english",
        };
      }
      this.$store.commit("app/UPDATE_LANGUAGE", obj.locale);
      return obj;
    },
  },
  methods: {
    loginFromAdmin(){
      let token = this.$route.params.token;
      this.$http
          .post("/getUserData",{authorization:token})
          .then((res) => {
            if (res.data.status === 200) {
              const user = res.data.value;

              this.$store.commit("auth/SET_AUTHENTICATED", true);
              this.$store.commit("auth/SET_USER", user);
              this.$store.commit("auth/UPDATE_TOKEN", user.token);
              this.$store.commit("auth/SHOW_ALERT", true);

              if (user.status == 1) {
                window.location.href = "/";
              } else {
                this.$router.push("/verify");
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("successfully_logged_in"),
                  icon: "SuccessIcon",
                  variant: "success",
                },
              });
            } else {
              this.$store.commit("auth/UPDATE_TOKEN", null);
              this.disableInputs = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t(
                      "failed_to_authenticate_with_provided_credentials."
                  ),
                  icon: "ErrorIcon",
                  variant: "error",
                },
              });
            }
          })
          .catch(() => {
            this.$store.commit("auth/SET_USER", []);
            this.$store.commit("auth/SET_AUTHENTICATED", false);
            this.$store.commit("auth/UPDATE_TOKEN", null);
            this.disableInputs = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t(
                    "failed_to_authenticate_with_provided_credentials."
                ),
                icon: "ErrorIcon",
                variant: "error",
              },
            });
          });
    },
    toggleLanguage(language) {
      this.$i18n.locale = language;
      this.language = language;
      this.$store.commit("app/UPDATE_LANGUAGE", language);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
